import { CreateEarlyDepositRequest, EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import { createApp, defineAsyncComponent } from 'vue';
import { enableClientInputs } from './add-file';

export function getClientEmailInputs() {
    return $('#addClientForm input[data-id^="email."]');
}

export function enableAllClientsInputs() {
    var emailInputs = getClientEmailInputs();

    $.each(emailInputs, function(idx, clientEmailInput) {
        var clientElement = $(clientEmailInput).parents('.client_contact_container');
        enableClientInputs(clientElement);
    });
}

//@TODO move this spaghetti jquery to a shared file, or better yet, just
// move this whole form to Vue.
export function verifyAllClientsHaveRoles(formSelector: JQuery.Selector) {
    const containerSelector = '.client_contact_container';
    const clientContainerElementList = $(formSelector).find(containerSelector);

    let shouldThrowError = false;

    // iterate through our client containers, verify that all have 
    // a role selected.
    clientContainerElementList.each(function(index, element) {
        const roleElement = $(element).find('.client-role:checked');
        if (!roleElement.length) {
            shouldThrowError = true;
            // show error message
            // and add event listener to remove error message
            $(element).find('.form-control-error-role').text('Please select a role');
            $(element).find('.client-role').on('change', function() {
                $(element).find('.form-control-error-role').text('');
            });
        }
    });
    if (shouldThrowError) {
        throw new Error('Please ensure all clients are properly configured.');
    }
}

/**
 * Client information is accessed in the form as individual pieces of
 * a Client record, plus an index indicating which record that piece of info
 * belongs to e.g.
 * <input name="email[0]"> (first client's email),
 * <input name="phone[2]"> (third client's phone),
 * etc.
 * 
 * The Create EMD endpoint is expecting an array of client objects, so this fn
 * constructs those objects from the inputs of individual data.
 */
export const zipClientsFromFormArrays = (clientsFormData: FormData): CreateEarlyDepositRequest['clients'] => {
    type ClientInputName = `role[${number}]` | `phone[${number}]` | `email[${number}]`;
    const clients: CreateEarlyDepositRequest['clients'] = [];
    const clientInputNameRegex = /(email|phone|role)\[\d+\]/;

    for (const key of clientsFormData.keys()) {
        if (clientInputNameRegex.test(key)) {
            const clientVal = clientsFormData.get(key); // the value of the <input>

            // e.g. split "email[0]" into ["email", "0]"]
            const [property, indexStr] = key.split('[') as ['role' | 'email' | 'phone', `${number}]`];
            const index = parseInt(indexStr, 10);

            if (!clients[index]) clients[index] = {} as CreateEarlyDepositRequest['clients'][number];
            clients[index][property] = `${clientVal}` as any; // the values for each input have been validated elsewhere
        }
    }

    return clients;
}

// type UnzippedClientArray
export const unzipClientsToFormArrays = (clients: Client[]): Record<keyof Client, Client[keyof Client][]> => {
    const ret: Record<keyof Client, Client[keyof Client][]> = {
        email: [], first: [], last: [], id: [], phone: [], role: []
    };

    clients.forEach(client => {
        ret.email.push(client.email);
        ret.first.push(client.first);
        ret.last.push(client.last);
        ret.id.push(client.id);
        ret.phone.push(client.phone);
        ret.role.push(client.role);
    });

    return ret;
}

export const convertFormDataToCreateFileRequest = (formData: FormData): ClosinglockCreateFileForm => {
    const stringFormData = Array.from(formData.entries()).map(([key, val]) => [key, `${val}`]);
    const file: ClosinglockCreateFileForm = Object.fromEntries(stringFormData);

    const notificationUserIds = formData.getAll('fileNotificationUserIds[]') as string[];
    file['fileNotificationUserIds[]'] = notificationUserIds;
    file['wireDoc_recipients[]'] = formData.getAll('wireDoc_recipients[]') as string[];

    return file;
}

export async function checkForSimilarPreFiles(file: ClosinglockCreateFileForm) {
    if (!file.address) return [];
    const response = await fetch(`/user/early-deposit/search?address=${file.address}`);
    const json: Record<'data', EarlyDeposit[]> = await response.json();
    return json.data;
}

export const mapCompanyBranchIdsToNames = (): Record<'company' | 'branch', string> => {
    const { selectCompanySelector, selectBranchSelector } = window;
    const getTextFromSelectedOption = (selector: string) => document.querySelector<HTMLSelectElement>(selector)?.selectedOptions?.[0].textContent?.trim();
    
    if (!selectCompanySelector) console.warn('[mapCompanyBranchIdsToNames] window.selectCompanySelector is undefined');
    if (!selectBranchSelector) console.warn('[mapCompanyBranchIdsToNames] window.selectBranchSelector is undefined');

    return {
        company: getTextFromSelectedOption(selectCompanySelector) ?? 'Your company',
        branch: getTextFromSelectedOption(selectBranchSelector) ?? 'Your branch',
    };
}

export const getEmdClientsOfRole = (emdClients: EarlyDeposit['clients'], filterByRole: Role): EarlyDeposit['clients'] =>
    emdClients.filter(client => client.role === filterByRole)

export type SimilarFilesProps = {
    fullFileFormData: FormData,
    similarEmds: EarlyDeposit[],
    submitFormWith: (emdId?: EarlyDeposit['id'], formData?: FormData) => JQueryXHR,
}

let unmountSimilarEmdListener: () => void;

export const UNMOUNT_SIMILAR_EMD_EVENT_NAME = 'unmount-similar-emd-found';

export const startSimilarFilesAppInstance = async (rootSelector: JQuery.Selector, rootProps: SimilarFilesProps) => {
    const rootEl = document.querySelector(rootSelector);
    if (!rootEl) return console.warn(`[Merge EMD and File] Cannot mount <DialogWrapper />: Could not find '${rootSelector}' in the DOM.`);

    if (unmountSimilarEmdListener) {
        rootEl.removeEventListener(UNMOUNT_SIMILAR_EMD_EVENT_NAME, unmountSimilarEmdListener);
    }

    const app = createApp({
        template: `<DialogWrapper>
    <template #default="rootProps">
        <emd-merge-flow v-bind="rootProps" />
    </template>
</DialogWrapper>`,
        components: {
            DialogWrapper: defineAsyncComponent(() => import('@/components/Views/Company/DialogWrapper.vue')),
            EmdMergeFlow: defineAsyncComponent(() => import('@/components/Views/Company/EmdMergeFlow.vue')),
        }
    }, rootProps);

    app.mount(rootEl);

    unmountSimilarEmdListener = () => {
        app.unmount();
    };

    rootEl?.addEventListener(UNMOUNT_SIMILAR_EMD_EVENT_NAME, unmountSimilarEmdListener);
}
